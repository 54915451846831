// import React, { useState } from 'react';
// import { Modal, Box, Typography, TextField, Button, IconButton } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
// import LockIcon from '@mui/icons-material/Lock';
// import SaveIcon from '@mui/icons-material/Save';
// import EmailIcon from '@mui/icons-material/Email';

// const CredentialsModal = ({ open, handleClose, handleSaveCredentials, theme }) => {
//   const [credentials, setCredentials] = useState({ email: '', password: '' });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setCredentials({ ...credentials, [name]: value });
//   };

//   return (
//     <Modal
//       open={open}
//       onClose={handleClose}
//       aria-labelledby="credentials-modal-title"
//       aria-describedby="credentials-modal-description"
//       sx={{
//         backdropFilter: "blur(6px)",
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//       }}
//     >
//       <Box
//         sx={{
//           width: "100%",
//           maxWidth: "500px",
//           bgcolor: "background.paper",
//           boxShadow: 24,
//           p: 4,
//           borderRadius: "16px",
//           border: `1px solid ${theme.palette.divider}`,
//           position: "relative",
//           overflow: "hidden",
//         }}
//       >
//         <IconButton
//           aria-label="close"
//           onClick={handleClose}
//           sx={{
//             position: "absolute",
//             right: 16,
//             top: 16,
//             color: theme.palette.grey[600],
//             "&:hover": {
//               color: theme.palette.primary.main,
//               backgroundColor: "rgba(0, 0, 0, 0.2)",
//             },
//           }}
//         >
//           <CloseIcon />
//         </IconButton>
//         <Typography
//           variant="h5"
//           gutterBottom
//           sx={{ 
//             color: theme.palette.primary.main, 
//             display: 'flex', 
//             alignItems: 'center',
//             mb: 2 
//           }}
//         >
//           <LockIcon sx={{ marginRight: 1, fontSize: 'inherit' }} />
//           Enter Cloud Credentials
//         </Typography>
//         <TextField
//           label="Email"
//           name="email"
//           value={credentials.email}
//           onChange={handleChange}
//           fullWidth
//           variant="standard"
//           sx={{ mb: 2 }}
//           InputProps={{
//             startAdornment: <EmailIcon position="start" />,
//           }}
//         />
//         <TextField
//           label="Password"
//           name="password"
//           type="password"
//           value={credentials.password}
//           onChange={handleChange}
//           fullWidth
//           variant="stadard"
//           sx={{ mb: 2 }}
//           InputProps={{
//             startAdornment: <LockIcon position="start" />,
//           }}
//         />
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={() => handleSaveCredentials(credentials)}
//           startIcon={<SaveIcon />}
//         >
//           Save
//         </Button>
//       </Box>
//     </Modal>
//   );
// };

// export default CredentialsModal;

import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, TextField, Button, IconButton, Alert, InputAdornment, endAdornment } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LockIcon from '@mui/icons-material/Lock';
import EmailIcon from '@mui/icons-material/Email';

const CredentialsModal = ({ open, handleClose, theme }) => {
  const [cloudEmail, setCloudEmail] = useState('');
  const [cloudPassword, setCloudPassword] = useState('');
  const [originalEmail, setOriginalEmail] = useState('');
  const [originalPassword, setOriginalPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); 
  const [editMode, setEditMode] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const userId = localStorage.getItem("userId");

  // Fetch existing credentials on mount
  useEffect(() => {
    if (open) {
      setLoading(true);
      axios
        .get(`${process.env.REACT_APP_PUBLIC_URL}/api/get_credentials/${userId}/`)
        .then((response) => {
          if (response.status === 200) {
            setCloudEmail(response.data.cimcloud_email);
            setCloudPassword(response.data.cimcloud_password);
            setOriginalEmail(response.data.cimcloud_email);
            setOriginalPassword(response.data.cimcloud_password);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching credentials', error);
          setLoading(false);
        });
    }
  }, [open, userId]);

  // Handle "Edit" button click
  const handleEdit = () => {
    setEditMode(true);
  };

  // Handle "Cancel" button click
  const handleCancel = () => {
    setEditMode(false);
    // Reset fields to original values
    setCloudEmail(originalEmail);
    setCloudPassword(originalPassword);
    setError('');
    setMessage('');
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword); // Toggle password visibility
  };

  // Handle "Save" button click
  const handleSave = async () => {
    if (cloudEmail.trim() === '' || cloudPassword.trim() === '') {
      setError('Both email and password are required.');
      return;
    }

    setError('');
    setMessage('');

    try {
      const response = await axios.post(`${process.env.REACT_APP_PUBLIC_URL}/api/save_credentials/`, {
        user_id: userId,
        cimcloud_email: cloudEmail,
        cimcloud_password: cloudPassword,
      });
      if (response.status === 200) {
        setMessage(response.data.message);
        setOriginalEmail(cloudEmail); // Update original values after successful save
        setOriginalPassword(cloudPassword);
        setEditMode(false);
      }
    } catch (error) {
      console.error('Error saving credentials', error);
      setError('Failed to save credentials');
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="credentials-modal-title"
      aria-describedby="credentials-modal-description"
      sx={{ backdropFilter: 'blur(4px)' }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          maxWidth: '400px',
          bgcolor: 'background.paper',
          boxShadow: 4,
          p: 3,
          borderRadius: '10px',
          border: `1px solid ${theme.palette.divider}`,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
            '&:hover': {
              color: theme.palette.primary.light,
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
            },
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h5" gutterBottom sx={{ color: theme.palette.primary.main }}>
          {loading ? 'Loading Credentials...' : 'Cloud Credentials'}
        </Typography>
        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        {message && <Alert severity="success" sx={{ mb: 2 }}>{message}</Alert>}
        <TextField
          label="Email"
          fullWidth
          variant="outlined"
          margin="normal"
          value={cloudEmail}
          onChange={(e) => setCloudEmail(e.target.value)}
          disabled={!editMode} // Disable input if not in edit mode
          InputProps={{
            startAdornment: <EmailIcon position="start" />,
          }}
        />
        <TextField
          label="Password"
          type={showPassword ? 'text' : 'password'} // Toggle between text and password based on showPassword state
          fullWidth
          variant="outlined"
          margin="normal"
          value={cloudPassword}
          onChange={(e) => setCloudPassword(e.target.value)}
          disabled={!editMode} // Disable input if not in edit mode
          InputProps={{
            startAdornment: <LockIcon position="start" />,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle confirm password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
          {editMode ? (
            <>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleCancel}
                sx={{ fontWeight: 'bold' }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                sx={{ fontWeight: 'bold' }}
              >
                Save
              </Button>
            </>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleEdit}
              sx={{ fontWeight: 'bold' , color:"white"}}
            >
              Edit
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default CredentialsModal;


// import React, { useState } from 'react';
// import { Modal, Box, Typography, TextField, Button, IconButton } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
// import axios from 'axios';

// const CredentialsModal = ({ open, handleClose, theme, userId }) => {
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');

//   const handleSubmit = async () => {
//     try {
//       const response = await axios.post('http://127.0.0.1:8000/api/save_credentials/', {
//         user_id: userId,
//         cimcloud_email: email,
//         cimcloud_password: password,
//       });
//       if (response.status === 200) {
//         alert('Credentials saved successfully');
//         handleClose();
//       }
//     } catch (error) {
//       console.error('Error saving credentials', error);
//     }
//   };

//   return (
//     <Modal
//       open={open}
//       onClose={handleClose}
//       aria-labelledby="credentials-modal-title"
//       aria-describedby="credentials-modal-description"
//       sx={{ backdropFilter: 'blur(4px)' }}
//     >
//       <Box
//         sx={{
//           position: 'absolute',
//           top: '50%',
//           left: '50%',
//           transform: 'translate(-50%, -50%)',
//           width: '90%',
//           maxWidth: '400px',
//           bgcolor: 'background.paper',
//           boxShadow: 4,
//           p: 3,
//           borderRadius: '10px',
//           border: `1px solid ${theme.palette.divider}`,
//         }}
//       >
//         <IconButton
//           aria-label="close"
//           onClick={handleClose}
//           sx={{
//             position: 'absolute',
//             right: 8,
//             top: 8,
//             color: theme.palette.grey[500],
//             '&:hover': {
//               color: theme.palette.primary.light,
//               backgroundColor: 'rgba(0, 0, 0, 0.1)',
//             },
//           }}
//         >
//           <CloseIcon />
//         </IconButton>
//         <Typography variant="h5" gutterBottom sx={{ color: theme.palette.primary.main }}>
//           Enter Cloud Credentials
//         </Typography>
//         <TextField
//           label="Email"
//           fullWidth
//           variant="outlined"
//           margin="normal"
//           value={email}
//           onChange={(e) => setEmail(e.target.value)}
//         />
//         <TextField
//           label="Password"
//           type="password"
//           fullWidth
//           variant="outlined"
//           margin="normal"
//           value={password}
//           onChange={(e) => setPassword(e.target.value)}
//         />
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={handleSubmit}
//           sx={{ mt: 2 }}
//         >
//           Save
//         </Button>
//       </Box>
//     </Modal>
//   );
// };

// export default CredentialsModal;
