import React, { useState, useEffect, useRef, useCallback } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Box,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  Paper,
  TextField,
  InputAdornment,
  Tooltip,
  Snackbar,
  Alert,
  Avatar,
  Typography,
  CircularProgress,
  Chip,
  Button,
  // Button,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ArticleIcon from '@mui/icons-material/Article';
import SendIcon from "@mui/icons-material/Send";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import CIMCOPILOT_WELCOME_MESSAGE from "./WelcomeMessage";
import Sidebar from "./SideBar";
import ChatbotResponse from "./chatbotResponse";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import axios from "axios";
// import { useAuth } from "../../context/AuthContext"; // Adjust the import path as needed
import UserProfileDropdown from "../userProfile/userProfile";
import UserQuestion from "./useQuestion";
import { styled } from "@mui/material/styles";
import ViewSidebarIcon from '@mui/icons-material/ViewSidebar';
import { useChat, ChatProvider } from "../../context/ChatContext";
import NewChatButton from "./newChat";
import SaveFeedback from "./SaveFeedback/SaveFeedback";
import ShortLogo from "../../assets/images/cimcopilot_short.png";
import UploadFile from "../FileUpload/FileUpload";
import DeviceList from "../cimPage/deviceList";// import { ConversationsProvider } from './context/ConversationsContext';
import parse from 'html-react-parser';
import LoadingIndicator from "./thinkingAnimation"

// import { ConversationsProvider } from "../../context/conversationContext";
import copilot from "../../assets/images/copilot.png";
import "./temp.css"

import "./ChatPage.css";

const drawerWidth = 280;

const MAX_FILE_SIZE = 2 * 1024 * 1024;

const theme1 = createTheme({
  palette: {
    primary: {
      main: "#00A0DC",
    },
    secondary: {
      main: "#C0EAEB",
    },
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          width: drawerWidth,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: 16,
          margin: "20px auto",
          height: "calc(100vh - 100px)",
          overflowY: "auto",
        },
      },
    },
    MuiBox: {
      styleOverrides: {
        root: {
          display: "flex",
          alignItems: "center",
          paddingTop: 16,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          flex: 1,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          marginLeft: 8,
        },
      },
    },
  },
});

const ChatPage = () => {

  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  // const [highlightedMessage, setHighlightedMessage] = useState(null);
  // const [welcomeDisplayed, setWelcomeDisplayed] = useState(false);
  const messagesEndRef = useRef(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  // const [feedback, setFeedback] = useState("");
  const [showFeedback, setShowFeedback] = useState(false);
  // const [currentAnswer, setCurrentAnswer] = useState(false);
  const [saveFeedbackResponse, setSaveFeedbackResponse] = useState({});
  // const [downloadconfig, setDownloadconfig] = useState("");/
  const [conversations, setConversations] = useState([]);
  const [conversationTitle, setConversationTitle] = useState("");
  // const [isTitleGenerated, setIsTitleGenerated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [responseText, setResponseText] = useState("");
  // const [response] = useState(CIMCOPILOT_WELCOME_MESSAGE);
  const navigate = useNavigate();
  // const [displayedMessage, setDisplayedMessage] = useState("");
  const [welcomeMessageDisplayed, setWelcomeMessageDisplayed] = useState(false);

  const [isLastMessageDoneTyping, setIsLastMessageDoneTyping] = useState(false);

  // You can store whether to show feedback or not, or compute it directly
  // but let's keep a simple approach:
  // const messagesEndRef = useRef(null);

  // We assume the last message is messages[messages.length - 1]
  const lastMessage = messages.length > 0 ? messages[messages.length - 1] : null;
  const isLastBotMessage = lastMessage?.sender === "bot";
  const isWelcome = lastMessage?.isWelcome ?? false;
  

  const [conversationId, setConversationId] = useState(0);

  // const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  // const [conversations, setConversations] = useState([]);
  // const [isFileAvailable, setIsFileAvailable] = useState(false);
  // const [configIds, setConfigIds] = useState([]);
  // const [alertOpen, setAlertOpen] = useState(false);
  const [newChatButtonSnackbaropen, setNewChatButtonSnackbaropen] =
    useState(false);
  
  // const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState("");
  const [uploadSuccess, setUploadSuccess] = useState(false)
  // const { logout } = useAuth();
  const userId = localStorage.getItem("userId");
  const authTokens = JSON.parse(localStorage.getItem("authTokens"));
  // console.log("auth: ", authTokens)
  // const access = authTokens?.access;
  // console.log("Access Token: ", access);
  const { access } = authTokens;

  // console.log("acess: ", access)
  const saveStateToSessionStorage = (key, value) => {
    sessionStorage.setItem(key, JSON.stringify(value));
  };

  const [file, setFile] = useState("");
  const [isPdfFile, setIsPdfFile] = useState('');
  // const []
  const [fileId, setFileId] = useState(false);
  // const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [message, setMessage] = useState('');


  const predefinedQuestions = [
    "How do I configure my CIM10 IoT Edge gateway?",
    "How do I push or upload a configuration file to the CIM10?",
    "How do I troubleshoot Modbus RS485 issues?",
    "How can I see the real-time device data in CIM10?",
    "What are the steps to integrate my energy meters with CIM10?",
    "How do I check the status of my connected devices in CIM10?",
    "How do I access the CIM10 webpage?",
    "How do I add a device (e.g., CIM10) in CIMCopilot?",
    "Explain the Fleet Manager feature of CIMCopilot.",
    "What are the features of CIMCopilot?",
    "What are the features of CIM10?"
  ];
  


  const getRandomQuestions = (num = 4) => {
    return predefinedQuestions
      .sort(() => 0.5 - Math.random())
      .slice(0, num);
  };
  const [showPredefinedQuestions, setShowPredefinedQuestions] = useState(true);
  const [randomQuestions, setRandomQuestions] = useState(getRandomQuestions());

  // const handleFileChange = async (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     try {
  //       const formData = new FormData();
  //       formData.append("pdf_file", file);
  
  //       const response = await fetch(`${process.env.REACT_APP_PUBLIC_URL}/api/upload/`, {
  //         method: "POST",
  //         body: formData,
  //       });
  
  //       if (!response.ok) {
  //         throw new Error("Failed to upload the PDF file.");
  //       }
  
  //       const result = await response.json();
  //       setFile(file);
  //       setFileId(result.pdf_id);
  //       setIsPdfFile(true);
  //       setUploadSuccess(true);  // Show success feedback
  //       setUploadError(null);
  //     } catch (error) {
  //       console.error("Error uploading file:", error);
  //       setFile(null);
  //       setIsPdfFile(false);
  //       setUploadError("Failed to upload the file. Please try again.");
  //       setUploadSuccess(false);
  //     }
  //   }
  // };



  
  // Function to get random 4 questions
  


  const handlePredefinedQuestionClick = (question) => {
    // addMessage(question, "user", false);
    setShowPredefinedQuestions(false); // Hide predefined questions after user clicks
    handleSubmit(question); // Call the submit function to send the question to the API
    setInputMessage("");
  };


  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    
    if (file) {
      try {
        // if (file.size > MAX_FILE_SIZE) {
        //   alert("File size exceeds the 40 MB limit. Please upload a smaller file.");
        //   return;
        // }
        setFile(file); // Attach the file to the frontend
        setIsPdfFile(event.target.value);
        // setUploadSuccess(true); // Display success message
        setUploadError(null);
        // sessionStorage.setItem("setispdffile ", setIsPdfFile)
        // Instead of uploading the file now, we will do it in the query submission
        sessionStorage.setItem('uploadedFile', JSON.stringify({
          name: file.name,
          size: file.size,
          type: file.type,
          // base64: base64String,
        }));
        console.log("File details on client:", {
          name: file.name,
          size: file.size,
          type: file.type,
        });
        
        alert("File attached and processed successfully! You can now ask your question.");
        
        // Automatically clear Snackbar after a few seconds
        setTimeout(() => {
          setUploadSuccess(false);
        }, 3000); // 3 seconds
      } catch (error) {
        console.error("Error attaching file:", error);
        setFile(null);
        // setIsPdfFile(false);
        setIsPdfFile('');
        // setUploadError("Failed to attach the file. Please try again.");
        setUploadSuccess(false);
        alert("Failed to attach the file. Please try again.");
        
        // Automatically clear Snackbar after a few seconds
        setTimeout(() => {
          setUploadError(null);
        }, 3000); // 3 seconds
      }
    }
  };

  
  


  

  useEffect(() => {
    const savedMessages = JSON.parse(sessionStorage.getItem("messages"));
    // const savedWelcomeDisplayed = JSON.parse(sessionStorage.getItem('welcomeDisplayed'));
    const savedShowFeedback = JSON.parse(
      sessionStorage.getItem("showFeedback"),
    );
    const savedConversations = JSON.parse(
      sessionStorage.getItem("conversations"),
    );
    const savedIsLoading = JSON.parse(sessionStorage.getItem("isLoading"));
    const savedConversationId = JSON.parse(
      sessionStorage.getItem("conversationId"),
    );
    const savedFile = JSON.parse(sessionStorage.getItem("uploadedFile"));
    console.log("saved file ", savedFile)
    if (savedFile) {
      setFile(savedFile);
      // setIsPdfFile(true);
    }
    // const savedWelcomeMessageDisplayed = JSON.parse(sessionStorage.getItem("welcomeMessageDisplayed"));

    // if (savedMessages) setMessages(savedMessages);
    if (savedMessages) {
    // Mark all saved messages as old messages (to avoid re-typing effect)
    const updatedMessages = savedMessages.map(msg => ({
      ...msg,
      isNewMessage: false, // Prevent typing effect
      isOldMessage: true    // ✅ Tag it as old message
    }));
    setMessages(updatedMessages);
  }
    // if (savedWelcomeDisplayed) setWelcomeDisplayed(savedWelcomeDisplayed);
    if (savedShowFeedback) setShowFeedback(savedShowFeedback);
    if (savedConversations) setConversations(savedConversations);
    if (savedIsLoading) setIsLoading(savedIsLoading);
    if (savedConversationId) setConversationId(savedConversationId);
    // if (savedWelcomeMessageDisplayed !== null) setWelcomeMessageDisplayed(savedWelcomeMessageDisplayed);
  }, []);

  useEffect(() => {
    saveStateToSessionStorage("messages", messages);
  }, [messages]);

  useEffect(() => {
    saveStateToSessionStorage("showFeedback", showFeedback);
  }, [showFeedback]);

  useEffect(() => {
    saveStateToSessionStorage("conversations", conversations);
  }, [conversations]);

  useEffect(() => {
    saveStateToSessionStorage("conversationId", conversationId);
  }, [conversationId]);

  // const handleUpload = async () => {
  //   if (!file) {
  //     setMessage("Please select a file first");
  //     return;
  //   }

  //   setUploading(true);

  //   const formData = new FormData();
  //   formData.append('file', file);
  //   formData.append('conversation_id', conversationId);

  //   try {
  //     const response = await fetch(`${process.env.REACT_APP_PUBLIC_URL}/api/upload-file/`, {
  //       method: 'POST',
  //       body: formData,
  //     });

  //     if (!response.ok) {
  //       throw new Error("Failed to upload file");
  //     }

  //     const data = await response.json();
  //     setMessage(data.message);
  //   } catch (error) {
  //     setMessage(`Error: ${error.message}`);
  //   } finally {
  //     setUploading(false);
  //   }
  // };

  // const toggleSidebar = () => {
  //   setIsSidebarOpen(!isSidebarOpen);
  // };
  // useEffect(() => {
  //   sessionStorage.setItem("messages", JSON.stringify(messages));
  //   sessionStorage.setItem("showFeedback", JSON.stringify(showFeedback));
  //   sessionStorage.setItem("conversations", JSON.stringify(conversations));
  //   sessionStorage.setItem("conversationId", JSON.stringify(conversationId));
  //   sessionStorage.setItem("welcomeMessageDisplayed", JSON.stringify(welcomeMessageDisplayed)); // Save welcome message state
  // }, [messages, showFeedback, conversations, conversationId, welcomeMessageDisplayed]);

  useEffect(() => {
    const checkWelcomeDisplayed = () => {
      const displayed = sessionStorage.getItem("welcomeDisplayed");
      if (!displayed) {
        displayWelcomeMessage();
        sessionStorage.setItem("welcomeDisplayed", "true");
        setWelcomeMessageDisplayed(true);
      }
    };

    checkWelcomeDisplayed();
  }, []);

  // useEffect(() => {
  //   if (!welcomeMessageDisplayed) {
  //     displayWelcomeMessage();
  //     setWelcomeMessageDisplayed(true);
  //   }
  // }, [welcomeMessageDisplayed]);

  
  
  //function to handle reset chat or new chat creation
  const resetChat = () => {
    // setMessages((prevMessages) => prevMessages.filter(msg => msg.sender === 'bot' && msg.text === CIMCOPILOT_WELCOME_MESSAGE)); // Keep the welcome message
    setMessages([]);

    
    const displayNewChatWelcomeMessage = [
      
      {
        // "text":`Hello there! I'm CIMCopilot, your intelligent digital assistant here to make your life easier when setting up your CIM10 IoT
        //  Edge gateway. Using cutting-edge AI, ML, and NLP technologies, I'm here to guide you through every step of the process, no matter 
        //  your technical background.\n\n**Setup Made Easy:**With my intuitive chat interface, I can understand your questions and provide 
        //  clear answers. Need help with physical device connections? No problem, I've got you covered. I can even generate and help you 
        //  download the configuration files you need.\n\n**Seamless Configuration:**Uploading configurations to your CIM10 devices? Piece of cake! 
        //  I'll walk you through the process step by step, ensuring everything runs smoothly.\n\n**Continuous Improvement:**I'm not just a one-time helper. I'm always learning from your interactions to provide even better assistance in the future. Think of me as your personal IoT guru, dedicated to 
        //  enhancing your experience with the CIM10 gateway.\n\n**User-Centric Support:**My goal is to minimize downtime and maximize your satisfaction. 
        //  With CIMCopilot by your side, managing your IoT devices has never been more seamless.\n\n`,
        
        // "text":"Hello there! I'm CIMCopilot, your intelligent digital assistant here to make your life easier when setting up your CIM10 IoT Edge gateway. Using cutting-edge AI, ML, and NLP technologies, I'm here to guide you through every step of the process, no matter your technical background.\n<b>Setup Made Easy:</b>\nWith my intuitive chat interface, I can understand your questions and provide clear answers. Need help with physical device connections? No problem, I've got you covered. I can even generate and help you download the configuration files you need.\n<b>Seamless Configuration:</b>\nUploading configurations to your CIM10 devices? Piece of cake! I'll walk you through the process step by step, ensuring everything runs smoothly.\n<b>Continuous Improvement:</b>\nI'm not just a one-time helper. I'm always learning from your interactions to provide even better assistance in the future. Think of me as your personal IoT guru, dedicated to enhancing your experience with the CIM10 gateway.\n<b>User-Centric Support:</b>\nMy goal is to minimize downtime and maximize your satisfaction. With CIMCopilot by your side, managing your IoT devices has never been more seamless.",
        "text":"Hi! I'm CIM Copilot, your assistant for setting up your CIM10 IoT Edge Gateway. Whether you're new or experienced, I use AI and NLP to guide you through the process.\n<b>Easy Setup:</b>\nI can quickly understand your questions and help you with device connections or generate and download configuration files.\n<b>Smooth Configuration:</b>\nI’ll walk you through uploading configurations step-by-step.\n<b>Always Improving:</b>\nI learn from interactions to get better at helping you. Think of me as your go-to guide for managing CIM10 devices.\n<b>User-Centric Support:</b>\nMy goal is to reduce downtime and make your experience as easy as possible.",
        
        "sender":"bot",
        "isMarkdown":false,
        isWelcome: true
      }
    ]
    console.log("displya new chat welcome: ", displayNewChatWelcomeMessage)
    setMessages(displayNewChatWelcomeMessage);
    // setMessages
    
    setShowFeedback(false);
    setConversations([]);
    setIsLoading(false);
    setConversationId(0);
     // Remove the file from session storage
  setFile(null); // Clear the file from state
  setIsPdfFile('');
    sessionStorage.removeItem("uploadedFile");
    sessionStorage.removeItem("messages");
    localStorage.removeItem("lastSentFile")
    // sessionStorage.removeItem("welcomeDisplayed");
    sessionStorage.removeItem("showFeedback");
    sessionStorage.removeItem("conversations");
    sessionStorage.removeItem("isLoading");
    sessionStorage.removeItem("conversationId");
    sessionStorage.removeItem("selectedDevice")
  };

  const handleNewChat = () => {
    resetChat();
    setNewChatButtonSnackbaropen(true);
    setShowPredefinedQuestions(true); // Show predefined questions again
    setRandomQuestions(getRandomQuestions()); // Get new random questions
  };

  useEffect(() => {
    const handleReload = () => {
      const { type } = performance.getEntriesByType("navigation")[0];
      if (type === "reload") {
        console.log("The application has been reloaded");
        setShowPredefinedQuestions(false);
      }
    };
    handleReload();
  }, []);
  

  const handleNewChatButtonSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNewChatButtonSnackbaropen(false); // Close the snackbar notification
  };

  const displayWelcomeMessage = () => {
    const welcomeMessages = CIMCOPILOT_WELCOME_MESSAGE.split();
    welcomeMessages.forEach((message, index) => {
      setTimeout(() => {
        addMessage(message.trim(), "bot", false, null, true);
      }, index * 2000); // Adjust timing if needed
    });
  };

  const addMessage = (text, sender, isMarkdown = false, file, isWelcome = false, id) => {
    const newMessage = {
      text,
      sender,
      isMarkdown,
      file, 
      isWelcome,
      isNewMessage: true,  // Mark new messages as 'new'
      id: id,
      
    };

    if (!isWelcome) {
      setSaveFeedbackResponse({ id });
      setShowFeedback(true);
    }
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setIsLastMessageDoneTyping(false);
    // if (storeMessage) {
    //   const updatedMessages = [...messages, newMessage];
    //   sessionStorage.setItem("messages", JSON.stringify(updatedMessages));
    // }
  };

  // const preprocessAnswer = (answer) => {
  //   let processedAnswer = answer
  //     // Handle bold text **bold**
  //     .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>")
  //     // Handle bullet points * item
  //     .replace(/^\* /gm, "● ")
  //     // Handle new lines
  //     .replace(/\n/g, "<br>")
  //     // Remove extra spaces
  //     .replace(/\s+/g, " ")
  //     // Correctly handle spaces before punctuation
  //     .replace(/\s([.,;!?])/g, "$1")
  //     .replace(/([.,;!?])\s/g, "$1 ");
  
  //   return processedAnswer.trim();
  // };


  
  const handleSubmit = async (question) => {
    // event.preventDefault();

    //
    setShowPredefinedQuestions(false);  
    if (typeof question !== 'string') {
      question = inputMessage;
    }
  
    // Ensure the question is a string and trim it
    if (typeof question === 'string' && question.trim() === "") return;

    const userId = localStorage.getItem("userId");
    console.log("user_id: ", userId);
    let fileToSend = file;
    const isPdfFile = fileToSend ? true : false

    // Clear the file from the text box
    
  
    // addMessage(inputMessage, "user", false, fileToSend);
  addMessage(question, "user", false, fileToSend);
  setIsLoading(true);

    // setFile(null);
    setIsPdfFile('');
    setInputMessage("");

    try {
      const userMessage = question;
      const chatHistory = messages.filter(
        (msg) => msg.sender === "user" || msg.sender === "bot",
      );
      

      // If there's no conversation ID, create a new conversation and generate a title
      let currentConversationId = conversationId;
      if (!currentConversationId) {
        const title = await generateChatTitle(userMessage);
        const newConversationId = await fetchNewConversationId(userId, title);
        console.log("new conv id", newConversationId);
        setConversationId(newConversationId);
        localStorage.setItem("conversationId", newConversationId);
        currentConversationId = newConversationId;

      }

      const answer = await fetchAnswer(
        userId,
        currentConversationId,
        userMessage,
        chatHistory,
        fileToSend, // Pass the file to fetchAnswer
        isPdfFile // Pass the PDF file flag
      );
      
      // const processedAnswer = preprocessAnswer(answer);
      // addMessage(processedAnswer, "bot", true);
      addMessage(answer, "bot", true);
      setIsLoading(false); //
      setShowFeedback(true);

      await saveMessage(
        userId,
        currentConversationId,
        userMessage,
        answer,
        conversationTitle,
        fileToSend, // Save the file name or null
        isPdfFile,
      );


    } catch (error) {
      console.error("Error handling message submission:", error.message);
    }
  };

  const fetchNewConversationId = async (userId, title) => {
    const response = await fetch(
      `${process.env.REACT_APP_PUBLIC_URL}/api/start_conversation/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // "Authorization": `Bearer ${access}`
        },
        body: JSON.stringify({
          user_id: userId,
          title: title,
        }),
      },
    );

    if (!response.ok) {
      throw new Error("Failed to start a new conversation");
    }

    const data = await response.json();
    console.log("conv_id", data);
    return data.conversation_id;
  };

  // const fetchAnswer = async (
  //   userId,
  //   conversationId,
  //   userMessage,
  //   chatHistory,
  //   file, 
  //   isPdfFile
  // ) => {

  //   // const formData = new FormData();
  //   // formData.append("user_id", userId);
  //   // formData.append("conversation_id", conversationId);
  //   // formData.append("user_question", userMessage);
  //   // formData.append("chat_history", JSON.stringify(chatHistory));
  //   // if (file) {
  //   //   formData.append("pdf_file", file); // Append the file if it exists
  //   // }

  //   // let formData = new FormData();
  //   // let pdf_id = null;
  //   // let is_pdf_file = false;

  //   // if (file) {
  //   //   // If a file is attached, upload it first
  //   //   formData.append("pdf_file", file);

  //   //   // Assuming there's an API endpoint to handle the file upload and return an ID
  //   //   const uploadResponse = await fetch(`${process.env.REACT_APP_PUBLIC_URL}/api/upload/`, {
  //   //     method: "POST",
  //   //     body: formData,
  //   //   });

  //   //   if (uploadResponse.ok) {
  //   //     const uploadResult = await uploadResponse.json();
  //   //     pdf_id = uploadResult.pdf_id; // Assuming the API returns the uploaded PDF's ID
  //   //     is_pdf_file = true;
  //   //   } else {
  //   //     throw new Error("Failed to upload the PDF file.");
  //   //   }
  //   // }
  //   const response = await fetch(
  //     `${process.env.REACT_APP_PUBLIC_URL}/api/query/`,
  //     {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         // "Authorization": `Bearer ${access}`,
  //       },
  //       body: JSON.stringify({
  //         user_id: userId,
  //         conversation_id: conversationId,
  //         user_question: userMessage,
  //         chat_history: chatHistory,
  //         pdf_id: file ? fileId : null, // Send the file ID if available
  //         is_pdf_file: isPdfFile,
  //       }),
  //       // body: formData // Use FormData for the request body
  //     },
  //   );

  //   if (!response.ok) {
  //     throw new Error("Failed to fetch answer from API");
  //   }

  //   const data = await response.json();
  //   return data.answer;
  // };

  // const fetchAnswer = async (
  //   userId,
  //   conversationId,
  //   userMessage,
  //   chatHistory,
  //   file, 
  //   isPdfFile
  // ) => {
  //   const response = await fetch(
  //     `${process.env.REACT_APP_PUBLIC_URL}/api/query/`,
  //     {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         // "Authorization": `Bearer ${access}`,
  //       },
  //       body: JSON.stringify({
  //         user_id: userId,
  //         conversation_id: conversationId,
  //         user_question: userMessage,
  //         chat_history: chatHistory,
  //         pdf_id: file ? fileId : null, // Send the file ID if available
  //         is_pdf_file: isPdfFile,
  //       }),
  //     }
  //   );

  //   if (!response.ok) {
  //     throw new Error("Failed to fetch answer from API");
  //   }

  //   const data = await response.json();
  //   return data.answer;
  // };




  // const fetchAnswer = async (
  //   userId,
  //   conversationId,
  //   userMessage,
  //   chatHistory,
  //   file, 
  //   isPdfFile
  // ) => {
  //   const formData = new FormData();
  //   formData.append("user_id", userId);
  //   formData.append("conversation_id", conversationId);
  //   formData.append("user_question", userMessage);
  //   formData.append("chat_history", JSON.stringify(chatHistory));
  //   if (file) {
  //     formData.append("pdf_file", file);
  //   }
  //   formData.append("is_pdf_file", isPdfFile);

  //   const response = await fetch(
  //     `${process.env.REACT_APP_PUBLIC_URL}/api/query/`,
  //     {
  //       method: "POST",
  //       // Do not set 'Content-Type' for FormData; browser will set it automatically
  //       body: formData,
  //     }
  //   );

  //   if (!response.ok) {
  //     throw new Error("Failed to fetch answer from API");
  //   }

  //   const data = await response.json();
  //   return data.answer;
  // };

  const fetchAnswer = async (
    userId,
    conversationId,
    userMessage,
    chatHistory,
    file // pass file directly if available
  ) => {
    const formData = new FormData();
    formData.append("user_id", userId);
    formData.append("conversation_id", conversationId);
    formData.append("user_question", userMessage);
    formData.append("chat_history", JSON.stringify(chatHistory));
    
    if (file) {
      formData.append("pdf_file", file); // Attach file if available
    }
    formData.append("is_pdf_file", isPdfFile);

    const response = await fetch(
      `${process.env.REACT_APP_PUBLIC_URL}/api/query/`, // Unified endpoint
      {
        method: "POST",
        body: formData,
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch answer from API");
    }

    const data = await response.json();
    // setFile(file);
    setIsPdfFile('')
    return data.answer;
  };




  const generateChatTitle = async (userMessage) => {
    const response = await fetch(
      `${process.env.REACT_APP_PUBLIC_URL}/api/chat_title/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // "Authorization": `Bearer ${access}`,
        },
        body: JSON.stringify({
          user_question: userMessage,
        }),
      },
    );

    if (!response.ok) {
      throw new Error("Failed to generate chat title");
    }

    const data = await response.json();
    return data.title;
  };

  const saveMessage = async (userId, conversationId, userMessage, answer, file, isPdfFile) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_PUBLIC_URL}/api/messages/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // "Authorization": `Bearer ${access}`,
          },
          body: JSON.stringify({
            user_id: userId,
            conversation: conversationId,
            question: userMessage,
            answer: answer,
            question_length: userMessage.length,
            answer_length: answer.length,
            file: file ? file.name : null, // Save the file name or null
            is_pdf_file: isPdfFile,
          }),
        },
      );

      if (!response.ok) {
        throw new Error("Failed to save message to database");
      }

      const data = await response.json();
      console.log("Message saved successfully:", data);
      setSaveFeedbackResponse({ id: data.id });
      console.log("id:", data.id);
    } catch (error) {
      console.error("Error saving message:", error.message);
    }
  };

  // const handleFeedback = async (feedbackType) => {
  //   if (!saveFeedbackResponse || !saveFeedbackResponse.id) {
  //     console.error("Feedback ID is missing");
  //     return;
  //   }

  //   console.log("Sending feedback for message ID:", saveFeedbackResponse.id);
  //   sessionStorage.setItem("feedbackresponseid",saveFeedbackResponse.id)

  //   try {
  //     // Send feedback to the backend
  //     const feedbackResponse = await fetch(
  //       `${process.env.REACT_APP_PUBLIC_URL}/api/messages/${saveFeedbackResponse.id}/feedback/`,
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           // 'Authorization': `Bearer ${access}`,
  //         },
  //         body: JSON.stringify({ feedback: feedbackType }), // Example: sending 'like' or 'dislike'
  //       },
  //     );

  //     if (!feedbackResponse.ok) {
  //       throw new Error("Failed to send feedback to backend");
  //     }

  //     const feedbackData = await feedbackResponse.json();
  //     console.log("Feedback sent successfully:", feedbackData);
  //     setSnackbarMessage(
  //       feedbackType === "good" ? "Feedback saved" : "Feedback saved",
  //     );
  //     setSnackbarOpen(true);
  //     setShowFeedback(false); // Hide feedback buttons after feedback is sent
  //   } catch (error) {
  //     console.error("Error sending feedback:", error.message);
  //   }
  // };

  const handleFeedback = async (feedbackType) => {
    if (!saveFeedbackResponse || !saveFeedbackResponse.id) {
      console.error("Feedback ID is missing");
      return;
    }

    console.log("✅ Sending feedback for message ID:", saveFeedbackResponse.id);
    sessionStorage.setItem("feedbackresponseid", saveFeedbackResponse.id);

    try {
      // ✅ Send feedback to the backend
      const feedbackResponse = await fetch(
        `${process.env.REACT_APP_PUBLIC_URL}/api/messages/${saveFeedbackResponse.id}/feedback/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ feedback: feedbackType }),
        },
      );

      if (!feedbackResponse.ok) {
        throw new Error("Failed to send feedback to backend");
      }

      const feedbackData = await feedbackResponse.json();
      console.log("✅ Feedback sent successfully:", feedbackData);

      // ✅ Show a success snackbar
      // setSnackbarMessage("Thank you for your feedback.feedback ");
      setSnackbarMessage(
              feedbackType === "good" ? "Thank you for your feedback! Feedback saved" : "Thank you for your feedback! Feedback saved",
            );
      setSnackbarOpen(true);
      setShowFeedback(false); // ✅ Hide feedback buttons after submitting
      setSaveFeedbackResponse(null);
    } catch (error) {
      console.error("❌ Error sending feedback:", error.message);
    }
  };


  // const handleFileChange = (e) => {
  //   const uploadedFile = e.target.files[0];
  //   if (uploadedFile && uploadedFile.type === "application/pdf") {
  //     setFile(uploadedFile);
  //     setUploadError("");
  //   } else {
  //     setFile(null);
  //     setUploadError("Only PDF files are supported.");
  //   }
  // };
  // const handleFeedback = (type) => {
  //   // Your feedback saving logic here
  //   // Assuming feedback is saved successfully

  // };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  // const handleDownloadConfig = async () => {
  //   try {
  //     const response = await axios.get(
  //       `http://${process.env.REACT_APP_PUBLIC_URL}/api/get_latest_device_config/${conversationId}/`,
  //       {
  //         // const response = await axios.get(`http://${process.env.REACT_APP_PUBLIC_URL}/api/get_latest_device_config/`, {

  //         headers: {
  //           // Authorization: `Bearer ${token}`,
  //           // 'Authorization': `Bearer ${access}`
  //         },
  //       },
  //     );

  //     console.log("Response:", response.data);

  //     const configurationPayload = response.data.configuration_payload;
  //     const blob = new Blob([JSON.stringify(configurationPayload, null, 2)], {
  //       type: "application/json",
  //     });
  //     const url = window.URL.createObjectURL(blob);

  //     const a = document.createElement("a");
  //     a.href = url;
  //     a.download = `${response.data.device_name}_${response.data.device_type}.json`;
  //     document.body.appendChild(a);
  //     a.click();
  //     a.remove();
  //     window.URL.revokeObjectURL(url);
  //   } catch (error) {
  //     console.error("Error downloading the configuration file:", error.message);
  //     if (error.response) {
  //       console.error("Response data:", error.response.data);
  //     }
  //   }
  // };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };



  //function to handle input chat message multiline or single line
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSubmit(event);
    }
  };

  const CustomIconButton = styled(IconButton)(({ theme, colorType }) => ({
    color:
      colorType === "good"
        ? theme.palette.success.main
        : theme.palette.error.main,
    "&:hover": {
      backgroundColor:
        colorType === "good"
          ? theme.palette.success.light
          : theme.palette.error.light,
    },
  }));

  const theme = theme1;

  return (
    <ThemeProvider theme={theme}>
      {/* <Sidebar/> */}
      {/* <NewChat onNewChatCreated={handleNewChatCreated} /> Add the NewChat component */}

      <Box sx={{ display: "flex" }}>
        {/* <IconButton onClick={toggleDrawer}>
          <MenuIcon />
        </IconButton> */}

        <Drawer
          sx={{ flexShrink: 0, position: "fixed" }}
          variant="permanent"
          anchor="left"
          open={drawerOpen}
          // onClose={toggleDrawer}
        >
          <div
            sx={{
              display: "flex",
              alignItems: "center",
              padding: theme.spacing(0, 1),
            }}
          >
            {/* <IconButton onClick={toggleDrawer} disabled>
              <ChevronLeftIcon sx={{color:"black"}}/>
            
            </IconButton> */}
            {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginBottom: "16px",
              marginRight: "16px",
              paddingLeft:"50px",
            }}
          > */}
            {/* <Avatar
              src="sideicon.png"
              alt="CIMCopilot Logo"
              sx={{ width: "24px", height: "24px", marginRight: 2 }}
            />
            <Typography variant="h6" style={{ marginTop: 6, fontWeight: "bold" }}>
              CIMCopilot
            </Typography> */}
            {/* <div className="logo">
              <img
                src={copilot}
                alt="logo"
                style={{
                  width: "120%",
                  maxWidth: "200px",
                  marginBottom: "16px",
                }}
              />
            </div> */}
          {/* </Box> */}
            
          </div>

          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: theme.spacing(0, 1),
            }}
          >
            <IconButton onClick={toggleDrawer} disabled>
              <ChevronLeftIcon sx={{color:"black"}}/>
            </IconButton>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "16px",
                flexGrow: 1, // Ensures this Box takes up remaining space
              }}
            >
              <Avatar
                src={ShortLogo}
                alt="CIMCopilot Logo"
                sx={{ width: 24, height: 24, marginRight: 2 }}
              />
              <Typography variant="h6" style={{ marginTop: 6 }}>
                CIMCopilot
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <NewChatButton
                sx={{
                  backgroundColor: theme.palette.secondary.light,
                }}
                onNewChat={handleNewChat}
              />
              <Snackbar
                open={newChatButtonSnackbaropen}
                autoHideDuration={3000} // Adjust the duration as needed
                onClose={handleNewChatButtonSnackbarClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              >
                <Alert
                  onClose={handleNewChatButtonSnackbarClose}
                  severity="success"
                  variant="filled"
                  sx={{ width: "100%", height: "10%" }}
                >
                  New chat created!
                </Alert>
              </Snackbar>
            </Box>
          </div> */}
          
          {/* <Divider /> */}

        
          {/* <NewChatButton
              sx={{
                // margin: "20px",
                backgroundColor: theme.palette.secondary.light,
              }}
              onNewChat={handleNewChat}
            /> */}
            <Snackbar
              open={newChatButtonSnackbaropen}
              autoHideDuration={3000} // Adjust the duration as needed
              onClose={handleNewChatButtonSnackbarClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
              <Alert
                onClose={handleNewChatButtonSnackbarClose}
                severity="success"
                variant="filled"
                sx={{ width: "100%", height: "10%" }}
              >
                New chat created!
              </Alert>
            </Snackbar>
            {/* <ConversationsProvider> */}
              <Sidebar 
                conversations={conversations}
                handleNewChat={handleNewChat}
              />
            {/* </ConversationsProvider> */}
            
        </Drawer>

        <Box
          sx={{
            flexGrow: 1,
            padding: theme.spacing(3),
            paddingLeft: 40,
            marginTop: 5,
          }}
        >
          <Grid container justifyContent="center" style={{}}>
            <Grid item xs={12} sm={8}>
              <Paper
                sx={{
                  // padding: theme.spacing(2),
                  // margin: "20px auto",
                  margin: "0px",
                  height: "calc(100vh - 150px)",
                  width: "125%",
                  overflowY: "auto",
                  // height: "relative",
                  boxShadow: "none",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <Box sx={{ 
                    flexGrow: 1, 
                    overflowY: "auto" 
                    }}>
                    {messages.map((message, index) => {
        const isLastMessage = index === messages.length - 1;

        if (message.sender === "user") {
          return (
            <Box
                        key={index}
                        sx={{
                          // display: 'flex',
                          backgroundColor:
                            message.sender === "user"
                              ? theme.palette.primary
                              : theme.palette.secondary,
                          // flexDirection: message.sender === "user" ? "row-reverse" : "row",
                          color: theme.palette.text.primary,
                          borderRadius: 2,
                          padding: theme.spacing(1),
                          margin: theme.spacing(1, 0),
                          alignSelf:
                            message.sender === "user"
                              ? "flex-start"
                              : "flex-end",
                          maxWidth: "80%",
                          wordBreak: "break-word",
                        }}
                      >
              <UserQuestion text={message.text} file={message.sender === "user" ? message.file || file : null}/>
            </Box>
          );
        } else {
          // Bot message
          return (
            <Box
                        key={index}
                        sx={{
                          // display: 'flex',
                          backgroundColor:
                            message.sender === "user"
                              ? theme.palette.primary
                              : theme.palette.secondary,
                          // flexDirection: message.sender === "user" ? "row-reverse" : "row",
                          color: theme.palette.text.primary,
                          borderRadius: 2,
                          padding: theme.spacing(1),
                          margin: theme.spacing(1, 0),
                          alignSelf:
                            message.sender === "user"
                              ? "flex-start"
                              : "flex-end",
                          maxWidth: "80%",
                          wordBreak: "break-word",
                        }}
                      >
              <ChatbotResponse
                text={message.text}
                // enableTextEffect={message.isNewMessage}
                enableTextEffect={message.isNewMessage && !message.isOldMessage}
                skipInitialAnimation={message.isWelcome}
                // When typewriter completes:
                onAnimationComplete={() => {
                  // If it's indeed the last message, mark it done
                  if (isLastMessage && !message.isWelcome) {
                    setIsLastMessageDoneTyping(true);
                  }
                }}
              />
            </Box>
          );
        }
      })}

      {/* 
         Render the feedback *once*, after the loop, only if:
         1) There's at least one message
         2) The last message is from bot
         3) It's NOT welcome
         4) The typewriter is done
      */}
      {lastMessage && isLastBotMessage && !isWelcome && isLastMessageDoneTyping && (
        <SaveFeedback
          showFeedback={showFeedback}
          handleFeedback={handleFeedback}
          snackbarOpen={snackbarOpen}
          handleCloseSnackbar={handleCloseSnackbar}
          snackbarMessage={snackbarMessage}
          messagesEndRef={messagesEndRef}
          isLatestResponse={true}
          isWelcome={isWelcome}
        />
      )}
                    {isLoading && <LoadingIndicator />}
                    <div style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    {/* <SaveFeedback
                        showFeedback={showFeedback}
                        handleFeedback={handleFeedback}
                        snackbarOpen={snackbarOpen}
                        handleCloseSnackbar={handleCloseSnackbar}
                        snackbarMessage={snackbarMessage}
                        messagesEndRef={messagesEndRef}
                      /> */}
                    </div>

                  </Box>
                  {/* {showPredefinedQuestions && (
  <Box 
    sx={{ 
      display: 'flex', 
      flexDirection: 'row', 
      alignItems: 'center', 
      justifyContent: 'center', 
      position: 'absolute', 
      top: '59%', 
      left:  '53.3%', 
      transform: 'translate(-50%, -50%)', 
      flexWrap: 'wrap' // This allows the buttons to wrap if needed
    }}
  >
    {randomQuestions.map((question, index) => (
      <Button
        key={index}
        variant="outlined"
        // color="secondary"
        // color="secondary"
        sx={{ margin: theme.spacing(1),
          width: '188px', // Set a fixed width
          height: '100px',
        textTransform:'none',
        color:"black",
        borderRadius: 2,
        boxShadow: 1,
        fontSize:"15px",
        '&:hover': {
          backgroundColor: theme.palette.secondary.main, // Background color on hover
          borderColor: theme.palette.secondary.main, // Change the border color to secondary
          color: 'black' // Change the text color on hover
        },
      
      
      }}  // Set a fixed height }}
        onClick={() => handlePredefinedQuestionClick(question)}
      >
        {question}
      </Button>
    ))}
  </Box>
)} */}

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",

                      alignItems: "center",
                      position: "fixed",
                      // height: "20%",
                      bottom: 0,
                      width: "50%",
                      background: "white",
                      backgroundColor:"transparent"
                      
                    }}
                  >
  {showPredefinedQuestions && (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row", // Align buttons in a row
        alignItems: "center",
        justifyContent: "center", // Center the row of buttons
        flexWrap: "nowrap", // Ensure buttons stay in one row
        width: "100%", // Match the width of the text field
        maxWidth: "800px", // Set max width to match the text field
        marginBottom: theme.spacing(2), // Add some space below the buttons
      }}
    >
      {randomQuestions.map((question, index) => (
        <Button
          key={index}
          variant="outlined"
          sx={{
            margin: theme.spacing(1),
            flexGrow: 1, // Allow buttons to expand evenly
            // width: 'auto', // Let the button size itself based on the text
            width: '188px', // Set a fixed width
          height: '100px',
            textTransform: 'none',
            color: "black",
            borderRadius: 2,
            boxShadow: 1,
            fontSize: "15px",
            '&:hover': {
              backgroundColor: theme.palette.secondary.main,
              borderColor: theme.palette.secondary.main,
              color: 'black',
            },
          }}
          onClick={() => handlePredefinedQuestionClick(question)}
        >
          {question}
        </Button>
      ))}
    </Box>
  )}
                    {/* <Tooltip title="Download Configuration"> */}
                    {/* <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleDownloadConfig}
                        style={{
                          // position: "fixed",

                          bottom: "8px",
                          right: "8px",
                          paddingTop: "8px",
                          paddingBottom: "8px",
                          marginBottom: "-20px"
                        }}
                      >
                        <DownloadIcon />
                      </Button> */}
                    {/* <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleDownloadConfig}
                        disabled={!isFileAvailable}
                        style={{
                          bottom: "8px",
                          right: "8px",
                          paddingTop: "8px",
                          paddingBottom: "8px",
                          marginBottom: "-20px",
                        }}
                      >
                        <DownloadIcon />
                      </Button> */}
                    {/* <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleAlertClose}>
                        <Alert onClose={handleAlertClose} severity="success">
                          File is available for download!
                        </Alert>
                      </Snackbar> */}
                    {/* </Tooltip> */}




                    {/* <div>
                      <input
                        accept="application/pdf"
                        style={{ display: 'none' }}
                        id="file-upload"
                        type="file"
                        onChange={handleFileChange}
                      />
                      <label htmlFor="file-upload">
                        <Button variant="contained" component="span">
                          Choose File
                        </Button>
                      </label>
                      {file && <Typography variant="body2">{file.name}</Typography>}
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleUpload}
                        disabled={uploading}
                        style={{ marginLeft: 10 }}
                      >
                        {uploading ? <CircularProgress size={24} /> : 'Upload'}
                      </Button>
                      {message && <Typography variant="body2" color="error" style={{ marginTop: 10 }}>{message}</Typography>}
                    </div> */}



                    {/* <UploadFile /> */}



                  <form
                      onSubmit={(e) => {
                        e.preventDefault(); // Prevent default form submission
                        handleSubmit(); // Call handleSubmit without passing an event
                      }}
                      style={{
                        display: "center",
                        // position:"fixed"
                        paddingTop: theme.spacing(1),
                      }}
                    >
                      
                      <TextField
                        style={{
                          borderRadius: 2,
                          flexGrow: 1,
                          width: "800px",
                          background: "white",
                          // wid
                          overflowY: "auto",
                          color: "primary",
                          boxShadow: "0px 0px 5px rgba(0,0,0,0.1)", // Add a subtle shadow
                          // marginTop: isPdfFile && file ? "8px" : "0", // If there's an attachment, add margin to the text area
                        
                          // overflow-y: 'scroll'
                          // height:"20vh"
                        }}
                        color="primary"
                        variant="outlined"
                        fullWidth
                        value={inputMessage}
                        onChange={(e) => setInputMessage(e.target.value)}
                        minRows="1"
                        maxRows="10"
                        multiline
                        margin="normal"
                        autoFocus
                        onKeyDown={handleKeyDown}
                        placeholder="Type your message..."
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Tooltip title="Attach file" arrow>
                              <IconButton
                                component="label"
                                // sx={{ left: 0, top: 10 }}
                              >
                                <AttachFileIcon
                                  style={{ position: "fixed", color: "black" }}
                                  
                                />
                                <input type="file" hidden accept=".pdf" onChange={handleFileChange} value={isPdfFile}/>
                            
                              </IconButton>
                              </Tooltip>
                              
                                {isPdfFile && file && file.name && (
                                  <div style={{
                                    // position: "absolute",
                                    // top: "-40px",
                                    display: 'flex',
                                    alignItems: 'center',
                                    // marginTop: '8px', // Space between text field and file display
                                    // marginBottom: '60px',
                                    padding: '4px',
                                    borderRadius: '8px',
                                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Subtle shadow
                                    backgroundColor: '#f5f5f5', // Light background for contrast
                                    // maxWidth: '800px', // Align with the text field width
  //                                     position: 'absolute',
  // top: '-40px', // This positions it above the text input
  // left: '0',
  // right: '0',
                                  }}>
                                    <Chip
                                      icon={<ArticleIcon />}
                                      label={file.name.length > 20 ? `${file.name.slice(0, 20)}...` : file.name}
                                      onDelete={() => setFile(null)}
                                      variant="outlined"
                                      style={{
                                        marginRight: '8px',
                                        backgroundColor: 'white',
                                        borderRadius: 2,
                                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Subtle shadow
                                      }}
                                    />
                                  </div>
                                )
                                }
                            </InputAdornment>
                          ),

                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton type="submit">
                                <SendIcon
                                  style={{ position: "fixed", color: "black" }}
                                />
                                {uploading ? <CircularProgress size={24} /> : <SendIcon style={{ position: "fixed", color: "black" }} />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {uploadError && <Snackbar open message={uploadError} />}
                      {uploadSuccess && <Snackbar open message="File uploaded successfully!" />}  
                   
                    </form> 



                    {/* <form
  onSubmit={handleSubmit}
  style={{
    display: "center",
    paddingTop: theme.spacing(1),
  }}
>
  <TextField
    style={{
      borderRadius: 2,
      width: "800px",
      color: "primary",
      boxShadow: "0px 0px 5px rgba(0,0,0,0.1)",
    }}
    color="primary"
    variant="outlined"
    fullWidth
    value={inputMessage}
    onChange={(e) => setInputMessage(e.target.value)}
    minRows="1"
    maxRows="10"
    multiline
    margin="normal"
    autoFocus
    onKeyDown={handleKeyDown}
    placeholder="Type your message..."
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <Tooltip title="Attach File">
          <IconButton component="label">
            <AttachFileIcon style={{ color: "black" }} />
            <input type="file" hidden accept=".pdf" onChange={handleFileChange} />
          </IconButton>
          </Tooltip>
        </InputAdornment>
      ),
      endAdornment: (
        <InputAdornment position="end">
          <Tooltip title="Send">
          <IconButton type="submit">
            <SendIcon style={{ color: "black" }} />
          </IconButton>
          </Tooltip>
        </InputAdornment>
      ),
    }}
    InputLabelProps={{
      shrink: true, // Ensure the label shrinks correctly when there's content
    }}
  />

  {file && (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        padding: "8px",
        borderRadius: "8px",
        backgroundColor: "#f5f5f5",
        width: "100%",
        boxSizing: "border-box", // Ensures the padding is included in the width
        marginTop: "8px", // Adjusts the space to keep it within the textbox
        boxShadow: "inset 0px 2px 4px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Chip
        icon={<ArticleIcon />}
        label={file.name}
        onDelete={() => setFile(null)}
        variant="outlined"
        style={{
          marginRight: "8px",
          backgroundColor: "white",
          borderRadius: 2,
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      />
    </div>
  )}
</form>  */}      
{/* <Box sx={{ width: 300, border: '1px solid lightgray', padding: 2, borderRadius: 2 }}>
                      <UserProfileDropdown />
                      
                      <DeviceList />
                    </Box> */}
                    <Box  
                      sx={{
                        position: 'fixed',
                        top: 80,           // Adjust this to fine-tune the vertical position
                        right: 0,         // Adjust this to fine-tune the horizontal position
                        // left: 1,
                        padding: 0,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,            // Space between the UserProfileDropdown and DeviceList
                        zIndex: 999,       // Ensures it stays on top of other components
                        
                      }}
                    >
                      <UserProfileDropdown />
                      <DeviceList />
                    </Box>
                    
                  </div>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default ChatPage;