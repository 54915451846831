import React, { useState, useEffect } from 'react';
import { Box, Avatar, Typography } from '@mui/material';
import chatbotIcon from '../../assets/images/cimcopilot_short.png'; // Adjust path as needed

const LoadingIndicator = () => {
  const [displayedText, setDisplayedText] = useState('');
  const animationSteps = ["Thinking.", "Thinking..", "Thinking..."];
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentStep((prev) => (prev + 1) % animationSteps.length);
    }, 1000); // Cycle every 1 second
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setDisplayedText(animationSteps[currentStep]);
  }, [currentStep]);

  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-start', margin: '10px 0' }}>
      <Avatar src={chatbotIcon} alt="Chatbot Icon" sx={{ marginRight: 2 }} />
      <Box sx={{ backgroundColor: '#E0F7FA', padding: 1, borderRadius: 2, maxWidth: '70%', boxShadow: 3 }}>
        <Typography>{displayedText}</Typography>
      </Box>
    </Box>
  );
};

export default LoadingIndicator;