import React, { useEffect, useState, useCallback } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  Box,
  Tooltip,
  Grid,
  MenuItem,
  Select,
  FormControl,
  Snackbar,
  Alert,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import MuiAlert from "@mui/material/Alert";
import Menu from "@mui/material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tableCellTheme, tableBodyCellTheme } from "../../theme/tableCellTheme";
import { orderJsonKeysDownload } from '../Utils/ModbusUtils';

// import { useNavigate } from 'react-router-dom';
import "./configurationPage.css";

// Define the theme
const theme = createTheme({
  palette: {
    primary: {
      main: "#00A0DC",
    },
    secondary: {
      main: "#C0EAEB",
    },
  },
  typography: {
    h2: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 500,
      color: "#333",
    },
    button: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 600,
    },
  },
});

const ConfigurationsPage = ({ onClose }) => {
  const [configurations, setConfigurations] = useState([]);
  const [filteredConfigs, setFilteredConfigs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [deviceType, setDeviceType] = useState("");
  const [showMore, setShowMore] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedConfig, setSelectedConfig] = useState(null);
  const [open, setOpen] = useState(true);
  const [isConfiguredWithCIMCloud, setIsConfiguredWithCIMCloud] =
    useState(null);

  const userId = localStorage.getItem("userId");
  // const navigate = useNavigate();
  // const authTokens = JSON.parse(localStorage.getItem('authTokens'));

  // const redirectChatPage = () => {
  //   navigate("/chat");
  // };

  useEffect(() => {
    const fetchConfigurations = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_PUBLIC_URL}/api/get_all_device_configurations/${userId}/`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          },
        );

        console.log("configurations ", response.data);

        if (response.status === 200) {
          // Assuming the configurations have a "created_at" field
          const sortedConfigs = response.data.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at),
          );

          setConfigurations(sortedConfigs); // Set the sorted configurations
          setFilteredConfigs(sortedConfigs.slice(0, 5)); // Show first 5 configurations initially
        }
      } catch (error) {
        console.error("Error fetching configurations:", error.message);
      }
    };

    fetchConfigurations();
  }, [userId]);

  const handleDownload = (config) => () => {
    // const element = document.createElement("a");
    // const file = new Blob(
    //   [JSON.stringify(config.configuration_payload, null, 2)],
    //   { type: "application/json" },
    // );
    // element.href = URL.createObjectURL(file);
    // element.download = `${config.device_name}_${config.device_type}.json`;
    // document.body.appendChild(element);
    // element.click();

    const configurationPayload = config.configuration_payload;
    const deviceType = config.device_type;

    let payloadToDownload;
    if (deviceType === "modbusrtu" || deviceType === "modbustcpip") {
      const orderedPayload = orderJsonKeysDownload(configurationPayload.export_device, deviceType);
      payloadToDownload = orderedPayload;
    } else if (deviceType.toLowerCase() === "analog") {
      payloadToDownload = {
        "AnalogInput": {
          request: "set_config",
          request_parameters: configurationPayload,
        }
      };
    } else if (deviceType.toLowerCase() === "digital") {
      payloadToDownload = {
        "DigitalInput": {
          request: "set_config",
          request_parameters: configurationPayload.di_config,
        }
      };
    } else if (deviceType.toLowerCase() === "ethernet") {
      payloadToDownload = {
        "ethernet": {
          request: "set_config",
          request_parameters: configurationPayload,
        }
      };
    } else if (deviceType.toLowerCase() === "gdm") {
      payloadToDownload = {
        "GDM": {
          request: "set_config",
          request_parameters: configurationPayload,
        }
      };
    } else {
      payloadToDownload = configurationPayload;
    }

    const blob = new Blob([JSON.stringify(payloadToDownload, null, 2)], {
      type: "application/json",
    });
    const url = window.URL.createObjectURL(blob);

    const element = document.createElement("a");
    element.href = url;
    element.download = `${config.device_name}_${deviceType}.json`;
    document.body.appendChild(element);
    element.click();
    element.remove();
    window.URL.revokeObjectURL(url);
  };

  const handleDelete = async (configId) => {
    if (window.confirm("Are you sure you want to delete this configuration?")) {
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_PUBLIC_URL}/api/delete_device_configuration/${userId}/${configId}/`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          },
        );

        if (response.status === 204) {
          setSnackbarMessage("Configuration deleted successfully.");
          setSnackbarSeverity("success");
          setSnackbarOpen(true);
          setConfigurations(
            configurations.filter((config) => config.id !== configId),
          );
          setFilteredConfigs(
            filteredConfigs.filter((config) => config.id !== configId),
          );
        }
      } catch (error) {
        console.error("Error deleting configuration:", error.message);
        setSnackbarMessage("Failed to delete configuration.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleMenuClick = (event, config) => {
    setAnchorEl(event.currentTarget);
    setSelectedConfig(config);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedConfig(null);
  };

  const handleDownloadClick = () => {
    if (selectedConfig) {
      handleDownload(selectedConfig)();
      handleMenuClose();
    }
  };

  const handleDeleteClick = () => {
    if (selectedConfig) {
      handleDelete(selectedConfig.id);
      handleMenuClose();
    }
  };

  // const handleSearch = (event) => {
  //   const term = event.target.value.toLowerCase();
  //   setSearchTerm(term);
  //   const filtered = configurations.filter(config =>
  //     config.device_name.toLowerCase().includes(term) ||
  //     config.device_type.toLowerCase().includes(term)
  //   );
  //   setFilteredConfigs(filtered.slice(0, showMore ? filtered.length : 5));
  // };

  // const handleDeviceTypeChange = (event) => {
  //   setDeviceType(event.target.value);
  //   handleSearch(); // Re-filter when device type changes
  // };

  // const handleSearchTermChange = (event) => {
  //   setSearchTerm(event.target.value);
  //   handleSearch(); // Re-filter when search term changes
  // };

  // const filterConfigurations = useCallback(() => {
  //   const lowercasedSearchTerm = searchTerm.toLowerCase();
  //   const filtered = configurations.filter((config) => {
  //     const matchesSearchTerm =
  //       config.device_name.toLowerCase().includes(lowercasedSearchTerm) ||
  //       config.device_type.toLowerCase().includes(lowercasedSearchTerm);
  //     const matchesDeviceType = deviceType
  //       ? config.device_type.toLowerCase() === deviceType.toLowerCase()
  //       : true;
  //     console.log("dtype: ",  config)
  //     // console.log("", deviceType)
  //     const matchesCIMCloudFilter =
  //       isConfiguredWithCIMCloud === null ||
  //       (isConfiguredWithCIMCloud
  //         ? config.is_configured_with_cimcloud
  //         : !config.is_configured_with_cimcloud);

  //     return matchesSearchTerm && matchesDeviceType && matchesCIMCloudFilter;
  //   });

  //   setFilteredConfigs(filtered.slice(0, showMore ? filtered.length : 5));
  // }, [searchTerm, deviceType, configurations, showMore, isConfiguredWithCIMCloud]);

  const normalizeDeviceType = (type) => {
    // Convert to lowercase first
    const lowerType = type.toLowerCase();

    // Specific cases for modbus types and CIM Cloud
    if (lowerType === "modbus rtu") {
      return "modbusrtu"; // Remove space for modbus rtu
    }
    if (lowerType === "modbus tcp/ip" || lowerType === "modbus tcpip") {
      return "modbustcpip"; // Normalize modbus tcp/ip by removing slashes and spaces
    }
    if (
      lowerType === "cim cloud" ||
      lowerType === "cim-cloud" ||
      lowerType === "cimcloud"
    ) {
      return "cim-cloud"; // Ensure CIM Cloud is always "cim-cloud"
    }

    // General normalization: replace spaces or slashes with hyphens
    return lowerType.replace(/\s+/g, "-").replace(/\/+/g, "-");
  };

  const filterConfigurations = useCallback(() => {
    const lowercasedSearchTerm = searchTerm.toLowerCase();
    const normalizedDeviceType = deviceType
      ? normalizeDeviceType(deviceType)
      : ""; // Normalize the deviceType input

    const filtered = configurations.filter((config) => {
      const normalizedConfigDeviceType = normalizeDeviceType(
        config.device_type,
      ); // Normalize the device_type from config

      const matchesSearchTerm =
        config.device_name.toLowerCase().includes(lowercasedSearchTerm) ||
        normalizedConfigDeviceType.includes(lowercasedSearchTerm); // Match search term with normalized device_type

      const matchesDeviceType = normalizedDeviceType
        ? normalizedConfigDeviceType === normalizedDeviceType // Compare normalized values
        : true;

      console.log("dtype: ", normalizedDeviceType);

      const matchesCIMCloudFilter =
        isConfiguredWithCIMCloud === null ||
        (isConfiguredWithCIMCloud
          ? config.is_configured_with_cimcloud
          : !config.is_configured_with_cimcloud);

      return matchesSearchTerm && matchesDeviceType && matchesCIMCloudFilter;
    });

    setFilteredConfigs(filtered.slice(0, showMore ? filtered.length : 5));
  }, [
    searchTerm,
    deviceType,
    configurations,
    showMore,
    isConfiguredWithCIMCloud,
  ]);

  useEffect(() => {
    filterConfigurations();
  }, [
    searchTerm,
    deviceType,
    configurations,
    showMore,
    isConfiguredWithCIMCloud,
    filterConfigurations,
  ]);

  const handleDeviceTypeChange = (event) => {
    setDeviceType(event.target.value);
  };

  // const handleDeviceTypeChange = (event) => {
  //   setDeviceType(event.target.value);
  // };

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleShowMore = () => {
    setShowMore(true);
    setFilteredConfigs(configurations);
  };

  const handleShowLess = () => {
    setShowMore(false);
    setFilteredConfigs(configurations.slice(0, 5)); // Show only the first 5 configurations
  };

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          p: 2,
          padding: "20px",
          backgroundColor: "aliceblue",
          borderRadius: "10px",
          height: "700px",
        }}
      >


        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
            // borderBottom: "1px solid #ddd",
          }}
        >
          <Typography variant="h4" component="div" sx={{ display: "flex", alignItems: "center", color: '#00A0DC',
                // fontWeight: 500,
                fontSize: '2rem ', fontWeight: "bold", }}>
            <BuildCircleIcon sx={{ mr: 1, color: '#00A0DC', fontSize: '2rem' }} />
            Configurations
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Optional Chat Button */}
        {/* <Button
          onClick={redirectChatPage}
          color="primary"
          variant="contained"
          size="small"
          style={{ position: 'fixed', top: '16px', left: '16px' }}
        >
          Chat
        </Button> */}

        {/* Search Filter */}
        {/* <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", top: 8, right: 8 }}
        >
          <CloseIcon />
        </IconButton> */}
        <Box
          sx={{
            mb: 2,
            position: "sticky",
            top: 0,
            backgroundColor: "aliceblue",
            zIndex: 1,
            pb: 2,
          }}
        >
          {/* <TextField
            fullWidth
            
            label="Search Device"
            placeholder="Search by Device Name or Device Type"
            value={searchTerm}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          /> */}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Device Name"
                placeholder="Search by Device Name"
                value={searchTerm}
                // onChange={handleSearch}
                onChange={handleSearchTermChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined">
                <Select
                  value={deviceType}
                  onChange={handleDeviceTypeChange}
                  displayEmpty
                >
                  <MenuItem value="">All Types</MenuItem>
                  <MenuItem value="Analog">Analog</MenuItem>
                  <MenuItem value="Digital">Digital</MenuItem>
                  <MenuItem value="Modbus RTU">Modbusrtu</MenuItem>
                  <MenuItem value="Modbus TCP/IP">Modbustcpip</MenuItem>
                  <MenuItem value="MQTT">MQTT</MenuItem>
                  <MenuItem value="CIMCloud">CIM-Cloud</MenuItem>
                  <MenuItem value="Ethernet">Ethernet</MenuItem>
                  <MenuItem value="Modem">Modem</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined">
                <Select
                  value={isConfiguredWithCIMCloud}
                  onChange={(event) =>
                    setIsConfiguredWithCIMCloud(event.target.value)
                  }
                  displayEmpty
                >
                  <MenuItem value={null}>All Configurations</MenuItem>
                  <MenuItem value={true}> Configured with CIMCloud</MenuItem>
                  <MenuItem value={false}>
                    Not Configured with CIMCloud
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        {/* <Box sx={{ height: '420px', overflowY: 'auto', boxShadow: 3,  }}> */}
        <TableContainer
          component={Paper}
          sx={{
            borderRadius: 2,
            overflow: "hidden",
            height: "420px",
            overflowY: "auto",
          }}
        >
          <Table
            aria-label="sticky table"
            stickyHeader
            // sx={{borderCollapse: "collapse"}}
          >
            <TableHead>
              <ThemeProvider theme={tableCellTheme}>
                <TableRow>
                  <TableCell>Sr.No</TableCell>
                  <TableCell>Device Name</TableCell>
                  <TableCell>Device Type</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </ThemeProvider>
            </TableHead>
            <TableBody>
              <ThemeProvider theme={tableBodyCellTheme}>
                {filteredConfigs.length > 0 ? (
                  filteredConfigs.map((config, index) => (
                    <TableRow key={config.id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{config.device_name}</TableCell>
                      <TableCell>{config.device_type}</TableCell>
                      <TableCell>
                        {new Date(config.created_at).toLocaleString()}
                      </TableCell>
                      <TableCell>
                        {/* <Tooltip title="Download Configuration">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleDownload(config)}
                          startIcon={<DownloadIcon />}
                          sx={{
                            backgroundColor: theme.palette.secondary.light,
                          }}
                        >
                          Download
                        </Button>
                      </Tooltip>
                      <Tooltip title="Delete Configuration">
                        <IconButton
                          onClick={() => handleDelete(config.id)}
                          color="secondary"
                          sx={{ ml: 1 }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip> */}
                        <IconButton
                          onClick={(event) => handleMenuClick(event, config)}
                          color="primary"
                          sx={{ color: "black" }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleMenuClose}
                        >
                          <MenuItem onClick={handleDownloadClick}>
                            <DownloadIcon sx={{ mr: 1 }} /> Download
                          </MenuItem>
                          <MenuItem onClick={handleDeleteClick}>
                            <DeleteIcon sx={{ mr: 1 }} /> Delete
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Typography variant="subtitle1" align="center">
                        No configurations found.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </ThemeProvider>
            </TableBody>
          </Table>
        </TableContainer>
        {/* </Box>    */}

        {/* Show More Button */}
        {configurations.length > 5 && !showMore && (
          <Tooltip title="See More Configurations">
            <Button
              onClick={handleShowMore}
              variant="contained"
              color="primary"
              sx={{
                mt: 2,
                borderRadius: "10px",
                backgroundColor: theme.palette.secondary.main,
                "&:hover": {
                  backgroundColor: theme.palette.secondary.dark,
                },
              }}
            >
              <ExpandMoreIcon />
              Show More
            </Button>
          </Tooltip>
        )}
        {showMore && (
          <Tooltip title="See Less Configurations">
            <Button
              onClick={handleShowLess}
              variant="contained"
              // color="primary"
              sx={{
                mr: 1,
                marginTop: 2,
                borderRadius: "10px",
                backgroundColor: theme.palette.secondary.main,
                boxShadow: 3,
                "&:hover": {
                  backgroundColor: theme.palette.secondary.dark,
                },
              }}
            >
              <ExpandLessIcon />
              Show Less
            </Button>
          </Tooltip>
        )}
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </ThemeProvider>
  );
};

export default ConfigurationsPage;
