// import React, { createContext, useContext, useState } from 'react';
// import {jwtDecode} from 'jwt-decode';

// const AuthContext = createContext();

// export const AuthProvider = ({ children }) => {
//   const [authTokens, setAuthTokens] = useState(() => 
//     localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null
//   );
//   const [user, setUser] = useState(() => 
//     localStorage.getItem('authTokens') ? jwtDecode(localStorage.getItem('authTokens')) : null
//   );

//   const login = (tokens) => {
//     setAuthTokens(tokens);
//     const decodedUser = jwtDecode(tokens.access);
//     setUser(decodedUser);
//     localStorage.setItem('authTokens', JSON.stringify(tokens));
//     localStorage.setItem('userId', decodedUser.user_id);
    
//   };

//   const logout = () => {
//     setAuthTokens(null);
//     setUser(null);
//     localStorage.removeItem('authTokens');
//     console.log("removed auth tokens: ", authTokens)
//     localStorage.removeItem('userId');
//   };

//   return (
//     <AuthContext.Provider value={{ authTokens, user, login, logout }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

// export const useAuth = () => useContext(AuthContext);



// import React, { createContext, useContext, useState, useEffect } from 'react';
// import Cookies from 'js-cookie';
// import {jwtDecode} from 'jwt-decode';
// import { useNavigate } from "react-router-dom";

// const AuthContext = createContext();

// export const AuthProvider = ({ children }) => {
//   const [authTokens, setAuthTokens] = useState(() => {
//     const token = localStorage.getItem('authTokens') || Cookies.get('authTokens');
//     return token ? JSON.parse(token) : null;
//   });

//   const [user, setUser] = useState(() => {
//     const token = localStorage.getItem('authTokens') || Cookies.get('authTokens');
//     return token ? jwtDecode(JSON.parse(token).access) : null;
//   });
//   const navigate = useNavigate();

//   const login = (tokens) => {
//     setAuthTokens(tokens);
//     const decodedUser = jwtDecode(tokens.access);
//     setUser(decodedUser);

//     // Save to localStorage
//     localStorage.setItem('authTokens', JSON.stringify(tokens));
//     localStorage.setItem('userId', decodedUser.user_id);

//     // Save to cookies
//     Cookies.set('authTokens', JSON.stringify(tokens), { expires: 2 / (24 * 60), secure: true, sameSite: 'Strict' });
//     Cookies.set('userId', decodedUser.user_id, { expires: 2 / (24 * 60), secure: true, sameSite: 'Strict' });
//   };

//   const logout = () => {
//     setAuthTokens(null);
//     setUser(null);

//     // Remove from localStorage
//     localStorage.removeItem('authTokens');
//     localStorage.removeItem('userId');
//     navigate("/login");

//     // Remove from cookies
//     Cookies.remove('authTokens');
//     Cookies.remove('userId');
//   };

//   // useEffect(() => {
//   //   const interval = setInterval(() => {
//   //     const cookieToken = Cookies.get('authTokens');
//   //     if (!cookieToken) {
//   //       logout(); // Logout the user if the cookie is missing
//   //       // navigate("/");
        
//   //     }
//   //   }, 1000); // Check every second

//   //   return () => clearInterval(interval); // Cleanup the interval on unmount
//   // }, []);

//   return (
//     <AuthContext.Provider value={{ authTokens, user, login, logout }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

// export const useAuth = () => useContext(AuthContext);

/* working auth context */


// import React, { createContext, useContext, useState, useEffect } from 'react';
// import Cookies from 'js-cookie';
// import { jwtDecode } from 'jwt-decode';
// import { useNavigate, useLocation } from "react-router-dom";

// const AuthContext = createContext();

// export const AuthProvider = ({ children }) => {
//   const [authTokens, setAuthTokens] = useState(() => {
//     const token = localStorage.getItem('authTokens') || Cookies.get('authTokens');
//     return token ? JSON.parse(token) : null;
//   });

//   const [user, setUser] = useState(() => {
//     const token = localStorage.getItem('authTokens') || Cookies.get('authTokens');
//     return token ? jwtDecode(JSON.parse(token).access) : null;
//   });
  
//   const navigate = useNavigate();
//   const location = useLocation();

//   const login = (tokens) => {
//     setAuthTokens(tokens);
//     const decodedUser = jwtDecode(tokens.access);
//     setUser(decodedUser);

//     // Save to localStorage
//     localStorage.setItem('authTokens', JSON.stringify(tokens));
//     localStorage.setItem('userId', decodedUser.user_id);

//     // Save to cookies
//     Cookies.set('authTokens', JSON.stringify(tokens), { expires: 7, secure: window.location.protocol === 'https:', sameSite: 'Strict' });
//     Cookies.set('userId', decodedUser.user_id, { expires: 7, secure: window.location.protocol === 'https:', sameSite: 'Strict' });
//   };

//   const logout = () => {
//     setAuthTokens(null);
//     setUser(null);

//     // Remove from localStorage
//     localStorage.removeItem('authTokens');
//     localStorage.removeItem('userId');
//     localStorage.clear();

//     // Remove from cookies
//     Cookies.remove('authTokens');
//     Cookies.remove('userId');

//     // Clear session storage
//     sessionStorage.clear();

//     // Only navigate to login if not already on login or signup page
//     if (location.pathname !== '/login' && location.pathname !== '/') {
//       navigate("/login");
//     }
//   };

//   // Check for cookie expiration on initial load
//   useEffect(() => {
//     const cookieToken = Cookies.get('authTokens');
//     if (!cookieToken && location.pathname !== '/login' && location.pathname !== '/') {
//       logout(); // Log the user out if the cookie is missing and not on login or signup page
//     }
//   }, [location.pathname]); // Trigger when the component mounts or pathname changes

//   // Optional: Automatically logout after cookie expiry
//   useEffect(() => {
//     const interval = setInterval(() => {
//       const cookieToken = Cookies.get('authTokens');
//       if (!cookieToken && location.pathname !== '/login' && location.pathname !== '/') {
//         logout(); // Logout the user if the cookie is missing and not on login or signup page
//       }
//     }, 1000); // Check every second

//     return () => clearInterval(interval); // Cleanup the interval on unmount
//   }, [location.pathname]);

//   return (
//     <AuthContext.Provider value={{ authTokens, user, login, logout }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

// export const useAuth = () => useContext(AuthContext);


import React, { createContext, useContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode'; // Importing jwtDecode to decode our access token
import { useNavigate, useLocation } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  // Initialize authTokens state from localStorage or cookies if available
  const [authTokens, setAuthTokens] = useState(() => {
    const token = localStorage.getItem('authTokens') || Cookies.get('authTokens');
    return token ? JSON.parse(token) : null;
  });

  // Initialize user state by decoding token if available
  const [user, setUser] = useState(() => {
    const token = localStorage.getItem('authTokens') || Cookies.get('authTokens');
    return token ? jwtDecode(JSON.parse(token).access) : null;
  });
  
  const navigate = useNavigate();
  const location = useLocation();

  // Function to log in the user and save tokens
  const login = (tokens) => {
    setAuthTokens(tokens);
    const decodedUser = jwtDecode(tokens.access);
    setUser(decodedUser);

    // Save tokens and user info to localStorage
    localStorage.setItem('authTokens', JSON.stringify(tokens));
    localStorage.setItem('userId', decodedUser.user_id);

    // Save tokens and user info to cookies for persistence
    Cookies.set('authTokens', JSON.stringify(tokens), { expires: 7, secure: window.location.protocol === 'https:', sameSite: 'Strict' });
    Cookies.set('userId', decodedUser.user_id, { expires: 7, secure: window.location.protocol === 'https:', sameSite: 'Strict' });

    // Redirect the user to /chatpage after successful login
    navigate('/chat');
  };

  // Function to log out the user and clear all stored data
  const logout = () => {
    setAuthTokens(null);
    setUser(null);

    // Remove tokens and user info from localStorage and clear everything
    localStorage.removeItem('authTokens');
    localStorage.removeItem('userId');
    localStorage.clear();

    // Remove tokens and user info from cookies
    Cookies.remove('authTokens');
    Cookies.remove('userId');

    // Clear any session storage if used
    sessionStorage.clear();

    // Redirect to /login if the current path is not already /login or /
    if (location.pathname !== '/login' && location.pathname !== '/') {
      navigate('/login');
    }
  };

  // Define public routes that do not require authentication
  const publicRoutes = ['/login', '/', '/forget-password', '/reset-password'];

  // On component mount and route change, check if token exists
  useEffect(() => {
    const cookieToken = Cookies.get('authTokens');
    // If no token exists and the current route is not public, logout
    if (!cookieToken && !publicRoutes.includes(location.pathname)) {
      logout();
    }
  }, [location.pathname]);

  // Optionally, set up an interval to periodically check token existence
  useEffect(() => {
    const interval = setInterval(() => {
      const cookieToken = Cookies.get('authTokens');
      if (!cookieToken && !publicRoutes.includes(location.pathname)) {
        logout();
      }
    }, 1000); // Check every second

    return () => clearInterval(interval); // Clean up on unmount
  }, [location.pathname]);

  return (
    <AuthContext.Provider value={{ authTokens, user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to allow easy access to AuthContext values
export const useAuth = () => useContext(AuthContext);

